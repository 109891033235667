<template>
  <v-card class="mx-auto" :disabled="tbl_ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'restitutions',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl_ldg || !tbl.length ? '' : ' (' + tbl.length + ')')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="tbl.length > 0" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="success"
                @click.stop="billDlg"
              >
                <v-icon> mdi-file-upload </v-icon>
              </v-btn>
            </template>
            <span v-text="'Crear factura'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-autocomplete
            label="Oficina"
            v-model="contractor_id"
            :items="contractors"
            item-value="id"
            :item-text="(v) => v.name"
            :loading="contractors_ldg"
            dense
            :disabled="tbl.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-radio-group
            v-model="is_rha"
            row
            class="mt-0"
            :disabled="tbl.length != 0"
          >
            <v-radio label="S. Hospitalarios" :value="true" />
            <v-radio label="S. Médicos" :value="false" />
          </v-radio-group>
        </v-col>
        <v-col cols="12" xs="12" md="6" class="text-right">
          <v-btn
            v-if="tbl.length == 0"
            small
            color="warning"
            @click.prevent="getTblData"
            :disabled="contractor_id == null"
          >
            Buscar
            <v-icon small right> mdi-magnify </v-icon>
          </v-btn>
          <v-btn v-else small @click.prevent="tbl = []">
            Cambiar
            <v-icon small right>mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="tbl_srch"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
            :disabled="tbl.length == 0"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_hdrs"
            :search="tbl_srch"
            :items="tbl"
            :loading="tbl_ldg"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.index="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <template v-slot:item.taxed="{ item }">
              {{ item.taxed_amount != null ? "-" : item.taxed }}
            </template>
            <template v-slot:item.selected="{ item }">
              <v-simple-checkbox v-model="item.selected" :ripple="false" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="stamp_dlg" scrollable persistent max-width="1800">
      <v-card tile :disabled="stamp_dlg_ldg" :loading="stamp_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> FACTURA </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="stamp_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="stamp">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'SELECCIONADOS'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-text="'#'" />
                              <th v-text="'Folio'" />
                              <th v-text="'Doc ID'" />
                              <th v-text="'ID SM'" />
                              <th v-text="'Paciente'" />
                              <th v-text="'Proveedor | Médico'" />
                              <th v-text="'Fecha envio'" />
                              <th v-text="'%IVA calc.'" />
                              <th v-text="'Subtotal calc.'" />
                              <th v-text="'IVA calc.'" />
                              <th v-text="'Gravado calc.'" />
                              <th v-text="'Excento calc.'" />
                              <th v-text="'Total calc.'" />
                              <th v-text="'Total factura'" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in stamp.items" :key="i">
                              <td
                                class="text-caption font-weight-bold"
                                v-text="i + 1"
                              />
                              <td v-text="item.folio" />
                              <td v-text="item.doc_id" />
                              <td v-text="item.insured_enrollment" />
                              <td v-text="item.insured_full_name" />
                              <td v-text="item.provider_name" />
                              <td v-text="item.insurance_reception_date" />
                              <td v-text="item.taxed == 0 ? '-' : item.taxed" />
                              <td
                                v-text="
                                  item.taxed == 0
                                    ? '-'
                                    : numberFormat(item.subtotal_amount)
                                "
                              />
                              <td v-text="numberFormat(item.tax_amount)" />
                              <td v-text="numberFormat(item.taxed_amount)" />
                              <td
                                v-text="numberFormat(item.zero_rate_amount)"
                              />
                              <td v-text="numberFormat(item.amount_calc)" />
                              <td v-text="numberFormat(item.amount)" />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(stamp.totals.taxed_amount)"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(stamp.totals.zero_rate_amount)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(stamp.totals.amount_calc)"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(stamp.totals.amount)"
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="stamp.bill.fiscal_name"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead></thead>
                          <tbody>
                            <tr>
                              <th v-text="'Razón Social'" />
                              <td
                                v-text="stamp.bill.customer_data.legal_name"
                              />
                            </tr>
                            <tr>
                              <th v-text="'RFC'" />
                              <td v-text="stamp.bill.customer_data.tax_id" />
                            </tr>
                            <tr>
                              <th v-text="'C.P.'" />
                              <td
                                v-text="stamp.bill.customer_data.address.zip"
                              />
                            </tr>
                            <tr>
                              <th v-text="'Uso del CFDI '" />
                              <td v-text="stamp.bill.use" />
                            </tr>
                            <tr>
                              <th v-text="'Forma de pago'" />
                              <td v-text="stamp.bill.payment_form" />
                            </tr>
                            <tr>
                              <th v-text="'Método de pago'" />
                              <td v-text="stamp.bill.payment_method" />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>

                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>Clave ProdServ</th>
                              <th>Concepto</th>
                              <th>Cant.</th>
                              <th>Unidad</th>
                              <th>Descuento</th>
                              <th>Precio Unitario</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(itm, i) in stamp.bill.items" :key="i">
                              <td v-text="itm.product.product_key" />
                              <td v-text="itm.product.description" />
                              <td v-text="itm.quantity" />
                              <td v-text="itm.product.unit_key" />
                              <td v-text="numberFormat(itm.discount)" />
                              <td v-text="numberFormat(itm.product.price)" />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="'Subtotal'"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(stamp.totals.subtotal_amount)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="'Descuento'"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(0)"
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td class="font-weight-bold" v-text="'IVA 16%'" />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(stamp.totals.tax_amount)"
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td class="font-weight-bold" v-text="'Total'" />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(stamp.totals.bill_amount)"
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn color="success" @click.prevent="restitutionStore">
                Generar Factura
                <v-icon right> mdi-file-upload </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  msgAlert,
  msgConfirm,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    DatePicker,
    ViewData,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      tbl_ldg: false,
      tbl: [],
      tbl_srch: "",
      tbl_hdrs: [
        {
          value: "index",
          text: "#",
          sortable: false,
          filterable: false,
          width: "45",
        },
        {
          value: "folio",
          text: "Folio",
          filterable: true,
        },
        {
          value: "doc_id",
          text: "Doc ID",
          filterable: true,
        },
        {
          value: "insured_enrollment",
          text: "ID SM",
          filterable: true,
        },
        {
          value: "insured_full_name",
          text: "Paciente",
          filterable: true,
        },
        {
          value: "provider_name",
          text: "Proveedor | Médico",
          filterable: true,
        },
        {
          value: "description",
          text: "Descripción de servicio",
          filterable: true,
        },
        {
          value: "xml_folio",
          text: "Núm. factura",
          filterable: true,
        },
        {
          value: "insurance_reception_date",
          text: "Fecha envío",
          filterable: true,
        },
        {
          value: "taxed",
          text: "%IVA Calc.",
          filterable: true,
        },
        {
          value: "amount",
          text: "Total factura",
          filterable: true,
        },
        {
          value: "selected",
          text: "",
          sortable: true,
          filterable: false,
          width: "45",
        },
      ],
      contractor_id: null,
      contractors: [],
      contractors_ldg: true,
      is_rha: true,
      stamp: null,
      stamp_dlg: false,
      stamp_dlg_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getTblData() {
      this.tbl_ldg = true;
      this.tbl = [];

      if (this.contractor_id != null) {
        Axios.post(
          URL_API + "/restitutions/list",
          {
            contractor_id: this.contractor_id,
            is_rha: this.is_rha,
          },
          headersToken(this.login.token)
        ).then((res) => {
          this.tbl = res.data.data;
          this.tbl_ldg = false;
        });
      } else {
        this.tbl_ldg = false;
      }
    },

    billDlg() {
      this.stamp = null;
      this.stamp_dlg_ldg = true;
      this.stamp_dlg = true;

      let items = [];
      for (const item of this.tbl) {
        if (item.selected) {
          items.push(item);
        }
      }

      if (items.length > 0) {
        Axios.post(
          URL_API + "/restitutions/preview/stamp",
          {
            is_rha: this.is_rha,
            contractor_id: this.contractor_id,
            items: items,
          },
          headersToken(this.login.token)
        ).then((res) => {
          this.stamp = res.data.data;
          this.stamp_dlg_ldg = false;
        });
      } else {
        this.stamp_dlg = false;

        this.$swal.fire(
          msgAlert(
            "warning",
            "Selecciona al menos un registro para proceder con la carga de la factura"
          )
        );
      }

      this.stamp_dlg_ldg = false;
    },

    restitutionStore() {
      this.$swal.fire(msgConfirm(`¿Confirma generar factura`)).then((resp) => {
        if (resp.isConfirmed) {
          this.stamp_dlg_ldg = true;

          let obj = Object.assign({}, this.stamp);
          obj.is_rha = this.is_rha;

          Axios.post(
            URL_API + "/restitutions",
            obj,
            headersToken(this.login.token)
          ).then((res) => {
            this.$swal.fire(
              msgAlert(res.data.success ? "success" : "error", res.data.message)
            );

            if (res.data.success) {
              this.$router.push({
                name: "restitutions",
              });
            } else {
              console.log(res.data.message);
            }

            this.stamp_dlg_ldg = false;
          });
        }
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + "/contractors/fiscal",
      headersToken(this.login.token)
    ).then((res) => {
      this.contractors = res.data.data;
      this.contractors_ldg = false;
    });
  },
};
</script>